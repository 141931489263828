$breakpoint01: 450px;
.header{
  @media (min-width:0px){
    margin:0px;
    background-color: rgb(10, 5, 45);
    color: white;

    .title {
      display: grid;
      grid-template-columns: 20vmin 25vmin auto;
      grid-template-rows: 20vmin 15vmin;
      grid-gap: 0px;

      .titleLogo {
        padding: 5%;
        height:18vmin;
        width:18vmin;
        grid-column:1/2;
        grid-row:1/2;
      }

      .titleChars {
        font-family:"Arial";
        font-weight:800;
        text-align:left;
        line-height: 0.8;
        grid-column:  2/3;
        grid-row: 1/2;
        font-size: 8vmin;
      }

      .langSwitchButton {
          display: grid;
          text-align: right;
          padding-right: 2vmin;
          grid-column:  3/4;
          grid-row: 1/2;
      }

      .langSwitchButton button {
          @media (min-width:0px){
            align-items: center;
            cursor:pointer;
            font-size:4vmin;
            width: 25vmin;
            height: 8vmin;
            padding:2%;
            margin:1%;
            background-color: rgb(230, 230, 255);
            border: none;
            border-radius: 5px;
          }
          @media (min-width:$breakpoint01){
            padding: 1%;
          }
        }

        .langSwitchButton button:hover{
          background-color: rgb(30, 15, 115);
          color:#fff;
          transition: 500ms;
        }

      .toggleButtonContainer {
        display: grid;
        grid-column:  1/4;
        grid-row: 2/3;
        justify-items: left;
        font-size: 6vmin;
        font-family: Arial;

        .hiddenToggleButton {
          display:none;
        }

        .hamburgerLines{
          padding-left: 2%;
          padding-top: 5%;
          cursor: pointer;

          .line1, .line2, .line3 {
            display:block;
            margin-bottom: 1vmin;
            height: 1vmin;
            width: 6vmin;
            border-radius: 10px;
            background: #fff;
            transition: 500ms;
          }
        }

        .menuList {
          background-color: rgb(10, 5, 45);
          padding: 0%;
          z-index: 2;
          width: 100%;
          position: relative;
        }

        .menuList .liEn {
          display: flex;
          align-items: center;
          overflow: hidden;
          height:0;
          background-color: rgb(10, 5, 45);
          padding-left: 5vmin;
        }

        .menuList .liJa {
          display: flex;
          align-items: center;
          overflow: hidden;
          height:0;
          background-color: rgb(10, 5, 45);
          font-size: 3.5vmin;
          padding-left: 5vmin;
        }

        .menuList li:hover {
          background-color: rgb(40, 130, 200);
          transition: 200ms;
        }

        .hiddenToggleButton:checked ~ .menuList li{
          height:10vmin;
          transition:500ms;
        }

        .hiddenToggleButton:checked ~ .hamburgerLines .line1 {
          transform: rotate(45deg) translate(0, 2.8vmin);
          transition: 500ms;
        }

        .hiddenToggleButton:checked ~ .hamburgerLines .line2 {
          opacity: 0;
          transition: 500ms;
        }

        .hiddenToggleButton:checked ~ .hamburgerLines .line3 {
          transform: rotate(-45deg) translate(0, -2.8vmin);
          transition: 500ms;
        }
      }
    }
  }

  @media (min-width:$breakpoint01){
    .title{
      height: 40vmin;
      .toggleButtonContainer{
        .hiddenToggleButton {
          display:none;
        }

        .hamburgerLines{
          display:none;
        }

        .menuList {
          background-color: rgb(10, 5, 45);
          z-index: 2;
          padding: 0%;
          width: 100%;
          position: relative;
        }

        .menuList .liEn{
          display: inline;
          height: 5vmin;
          padding-left:2%;
          padding-right:2%;
        }

        .menuList .liJa{
          display: inline;
          height: 5vmin;
          padding-left:2%;
          padding-right:2%;
        }
      }
    }
  }
}


