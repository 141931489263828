$breakpoint01: 450px;
.html{
  @media (min-width:0px){
    display:block;
    text-align:center;
    background-color: rgb(235, 235, 255);
  }
  @media (min-width:$breakpoint01){
  }

  .images {
    @media (min-width:0px){
      margin: 0%;
      padding: 5%;
      background-color: rgb(235, 235, 255);
      text-align: center;
      font-size: 5vmin;
    }
    @media (min-width:$breakpoint01){
      font-size: 3vmin;
    }
  }
}
