$breakpoint01: 450px;
.html{
  @media (min-width:0px){
    display:block;
    background-color: rgb(235, 235, 255);
  }
  @media (min-width:$breakpoint01){
  }

  .contactBody {
    @media (min-width:0px){
      text-align:center;
      font-size: 100%;
      padding:  5vmin;
      font-family:"Arial";
    }
    @media (min-width:$breakpoint01){
      font-size: 150%;
    }

    .contactBodyH1 {
      @media (min-width:0px){
        font-size: 200%;
        font-weight: bold;
      }
      @media (min-width:$breakpoint01){
        font-size: 200%;
        font-weight: bold;
      }
    }

    .contactBodyH2 {
      @media (min-width:0px){
        font-size: 150%;
        font-weight: bold;
      }
      @media (min-width:$breakpoint01){
        font-size: 150%;
        font-weight: bold;
      }
    }

    .laboroImg {
      height: 200px;
      width: 200px;
    }

    .contactButtonContainer {
      .contactButton{
        @media (min-width:0px){
          text-align:center;
          width:80%;
          height: 15vmin;
          background: rgb(230, 230, 255);
          color: rgb(40, 20, 140);
          border: 3px solid rgb(40, 20, 140);
          border-radius: 10px;
          padding:1vmin;
          margin:1vmin;
        }
        @media (min-width:$breakpoint01){
          cursor: pointer;
          font-size:120%;
          width:30%;
          margin-top:5%;
          margin-bottom:5%;
        }

        .contactButtonEn{
          @media (min-width:0px){
            font-size: 150%;
          }
          @media (min-width:$breakpoint01){
            font-size: 150%;
          }
        }
      }
      .contactButton:hover {
        @media (min-width:0px){
          transition-duration:500ms;
          background: rgb(40, 20, 140);
          border: 3px solid rgb(40, 20, 140);
          color: rgb(255, 255, 255);
        }
      }

    }
    
  
  }
  .topButton{
    @media (min-width:0px){
      text-align:center;
      width:50%;
      background: rgb(230, 230, 255);
      color: rgb(40, 20, 140);
      border: 3px solid rgb(40, 20, 140);
      border-radius: 10px;
      padding:3vmin;
      margin-top:30%;
      margin-bottom:50%;
      font-size: 6vmin;
    }
    @media (min-width:$breakpoint01){
      cursor: pointer;
      width:20%;
      font-size: 4vmin;
      margin-top:10%;
      margin-bottom:5%;
    }
  }
  .topButton:hover {
    @media (min-width:0px){
      transition-duration:500ms;
      background: rgb(40, 20, 140);
      border: 3px solid rgb(40, 20, 140);
      color: rgb(255, 255, 255);
    }
  }
}