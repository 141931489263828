$breakpoint01: 450px;
.html{
  @media (min-width:0px){
    display:block;
    background-color: rgb(235, 235, 255);
  }
  @media (min-width:$breakpoint01){
  }

  .aboutBody {
    @media (min-width:0px){
      background-color: rgb(235, 235, 255);
      text-align: center;
      font-size:150%;
      padding:  5vmin;
      font-family:"Arial";
      
    }
    @media (min-width:$breakpoint01){
    }

    .aboutH1 {
      @media (min-width:0px){
        text-align: left;
        font-size: 200%;
        font-weight: bold;
      }
      @media (min-width:$breakpoint01){
        text-align: center;
        font-size: 200%;
        font-weight: bold;
      }
    }
    .aboutH2 {
      @media (min-width:0px){
        text-align: left;
        font-size: 150%;
        font-weight: bold;
      }
      @media (min-width:$breakpoint01){
        text-align: center;
        font-size: 150%;
        font-weight: bold;
      }
    }

    .aboutImgCaption {
      @media (min-width:0px){
        text-align: left;
        padding-top:20%;
        font-style: italic; 
      }
      @media (min-width:$breakpoint01){
        text-align: center;
        padding-top:5%;
      }
    }

    .aboutDocumentImg {
      @media (min-width:0px){
        text-align: right;
        width:80%;
      }
      @media (min-width:$breakpoint01){
        text-align: right;
        width:40%;
      }
    }

    .aboutImg01 {
      @media (min-width:0px){
        width:50%;
        transform:rotate(-10deg);
      }
      @media (min-width:$breakpoint01){
        width:20%;
      }
    }

    .aboutImg02 {
      @media (min-width:0px){
        width:50%;
        transform:rotate(10deg);
      }
      @media (min-width:$breakpoint01){
        width:20%;
      }
    }

    .aboutImg03 {
      @media (min-width:0px){
        width:50%;
        transform:rotate(-10deg);
      }
      @media (min-width:$breakpoint01){
        width:20%;
      }
    }

    .topButton{
      @media (min-width:0px){
        width:75%;
        background: rgb(230, 230, 255);
        color: rgb(40, 20, 140);
        border: 3px solid rgb(40, 20, 140);
        border-radius: 10px;
        padding:3vmin;
        margin-top:30%;
        margin-bottom:50%;
        font-size: 6vmin;
      }
      @media (min-width:$breakpoint01){
        cursor: pointer;
        width:20%;
        font-size: 4vmin;
        margin-top:10%;
        margin-bottom:5%;
      }
    }
    .topButton:hover {
      @media (min-width:0px){
        transition-duration:500ms;
        background: rgb(40, 20, 140);
        border: 3px solid rgb(40, 20, 140);
        color: rgb(255, 255, 255);
      }
    }
  }
}
