$breakpoint01:	450px;

.thumbnail {
	@media (min-width:0px) {
		display:  inline-flex;
		align-items: center;
		justify-content: center;
		width: 270px;
		height: 270px;
		padding: 0%;
		margin:0%;
		/* border: 1px solid blue;   */
	}
	@media (min-width:$breakpoint01) {
		/* border: 1px solid blue;   */
	}
}
  
.thumbnailImg:hover {
	@media (min-width:0px){
	}
	@media (min-width:$breakpoint01){
		cursor: pointer;
		transform: scale(1.2);
		transition-duration: 0.5s;
	}
}

.overlay{
	@media (min-width:0px){
		position:fixed;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background-color:rgba(0,0,0,0.5);
		font-size: larger;
		align-items: center;
		justify-content: center;
		display:flex;
		z-index:2;
	}
	@media (min-width:$breakpoint01){
		
	}
}

.modalContent {
	@media (min-width:0px){
		width:70%;
		height:80%;
		overflow-y:scroll;
		padding:10%;
		font-size:75%;
		background:rgb(255, 255, 255);
		z-index: 3;
	}
	@media (min-width:$breakpoint01){
		width:50%;
		height:auto;
		padding: 2%;
		font-size:100%;
		display:flex;
		align-items: flex-start;
		justify-content: flex-start;
	}
}

.parameters{
	@media (min-width:0px){
		text-align:left;
	}
	/* Left column of the modal (desktop only)*/
	@media (min-width:$breakpoint01){
		width:  50%;
		height: 100%;
	}
}

.parameterComponents1 {
	@media (min-width:0px){
	}
	@media (min-width:$breakpoint01){
	}
}

.parameterComponents2 {
	@media (min-width:0px){
		margin-left:5%;
		font-size: 80%;
	}
	@media (min-width:$breakpoint01){
	}
}

/* General Checkbox */
.checkboxBox {
	@media (min-width:0px){
		text-align:	left;
		width:100%;
		height:8vmin;
	}
	@media (min-width:$breakpoint01){
		height:5vmin;
	}
}
.checkboxLabel {
	@media (min-width:0px){
		display: inline-block;
		text-align:	left;
		margin-right:1vmin;
	}
	@media (min-width:$breakpoint01){
		cursor: pointer;
	}
}

.hiddenCheckbox1,
.hiddenCheckbox2,
.hiddenCheckbox3,
.hiddenCheckbox4,
.hiddenCheckbox5 {
	display:none;
}

.checkboxMark {	
	@media (min-width:0px){
		display: inline-block;
		vertical-align: middle;
		align-items: left;
		width: 6vmin;
		height: 6vmin;
		background-color: rgb(230, 230, 255);
		border:1px solid rgb(40, 20, 140);
		border-radius: 1vmin;
	}
	@media (min-width:$breakpoint01){
		width: 4vmin;
		height: 4vmin;
	}
}
.checkboxMark:after{
	@media (min-width:0px){
		display: inline-block;
		align-items: left;
		width: 3vmin;
		height: 6vmin;
		position:relative;
		opacity: 0;
		content: "";
		left: 0.7vmin;
		bottom: 0.5vmin;
		border-right: 2vmin solid rgb(40, 20, 140);
		border-bottom: 2vmin solid rgb(40, 20, 140);
		border-radius: 0.5vmin;
		transform: rotate(45deg);
		bottom:30%;
	}
	@media (min-width:$breakpoint01){
		width: 2vmin;
		height: 4vmin;
		border-right: 1vmin solid rgb(40, 20, 140);
		border-bottom: 1vmin solid rgb(40, 20, 140);
	}
}
.checkboxMark:hover{
	transition-duration:250ms;
	background-color: rgba(230, 230, 255, 0.5);
	cursor: pointer;
}

.hiddenCheckbox1:checked ~.checkboxMark:after,
.hiddenCheckbox2:checked ~.checkboxMark:after,
.hiddenCheckbox3:checked ~.checkboxMark:after,
.hiddenCheckbox4:checked ~.checkboxMark:after,
.hiddenCheckbox5:checked ~.checkboxMark:after{
	opacity: 1;
}

/* General Radibutton */
.radiobuttonBox {
	@media (min-width:0px){
		text-align:	left;
		width:100%;
		height:8vmin;
	}
	@media (min-width:$breakpoint01){
		height:5vmin;
	}
}
.radiobuttonLabel {
	@media (min-width:0px){
		display: inline-block;
		text-align:	left;
		margin-right:1vmin;
	}
	@media (min-width:$breakpoint01){
		cursor: pointer;
	}
}

.hiddenRadiobutton {
	display:none;
}

.radiobuttonMark {	
	@media (min-width:0px){
		display: inline-block;
		vertical-align: middle;
		align-items: left;
		width: 6vmin;
		height: 6vmin;
		border-radius: 6vmin;
		background-color: rgb(230, 230, 255);
		border:1px solid rgb(40, 20, 140);
	}
	@media (min-width:$breakpoint01){
		width: 4vmin;
		height: 4vmin;
		border-radius: 4vmin;
	}
}

.radiobuttonMark:after {	
	@media (min-width:0px){
		content: "";
		display: inline-block;
		align-items: left;
		width: 6vmin;
		height: 6vmin;
		border-radius: 100%;
		background-color: rgb(40, 20, 140);
		border:0px solid rgb(40, 20, 140);
		opacity:0;
		transform: scale(0.8);
	}
	@media (min-width:$breakpoint01){
		width: 4vmin;
		height: 4vmin;
		border-radius: 4vmin;
	}
}

.radiobuttonMark:hover{
	transition-duration:250ms;
	background-color: rgba(230, 230, 255, 0.5);
	cursor: pointer;
}

.hiddenRadiobutton:checked ~.radiobuttonMark:after{
	opacity: 1;
}

/* Color palette */
.palette  {
	@media (min-width:0px){
	}
	@media (min-width:$breakpoint01){
	}
}

/* General slider */
.slider {
	@media (min-width:0px){
		margin-top:2vmin;
		bottom:6vmin;
		left:10vmin;
		width:70%;
	}
	@media (min-width:$breakpoint01){
		margin-top:0vmin;
		bottom:4vmin;
		left:6vmin;
	}
}
.slider  .sliderTrack {
	@media (min-width:0px){
		height: 2vmin;
	}
	@media (min-width:$breakpoint01){
	}
}
.slider  .sliderThumb {
	@media (min-width:0px){
		top: -1.7vmin;
		width: 4vmin;
		height: 4vmin;
		outline: none;
	}
	@media (min-width:$breakpoint01){
		top: -0.5vmin;
		width: 2vmin;
		height: 2vmin;
	}
}
.sliderThumb {
	cursor: pointer;
	position:absolute;
	z-index: 2;
	background: rgb(230, 230, 255);
	border: 3px solid rgb(40, 20, 140);
	border-radius: 100%;
	box-shadow: 0 0 2px 0 rgb(0 0 0 / 50%);
}
.sliderThumb:hover{
	transition-duration:250ms;
	background: rgb(255, 255, 255);
}
.sliderTrack {
	position: relative;
	background: rgb(180, 180, 180);
	border-radius: 10px;
}
.sliderTrack.sliderTrack-0 {
	background: rgb(130, 100, 250);
}

/* Right column of the modal */
.results{
	@media (min-width:0px){
	}
	@media (min-width:$breakpoint01){
		width:  50%;
		height: 100%;
		vertical-align: 0%;
		justify-content: center;
		align-content:  center;
	}
}

/* Sample image */
.sampleSvg {
	@media (min-width:0px){
		display:flex;
		justify-content: center;
		align-content:  center;
		margin-top:10%;
		/* padding-top: 10%;
		padding-bottom: 20%;
		border: 1px solid;*/
	}
	@media (min-width:$breakpoint01){
		margin-top:0px;
	}
}

/* Size label */
.imgSize {
	margin:5%;
}

/* Scale slider */
.scaleSliderBox {
	display:flex;
	justify-content: center;
	align-content:  center;
	padding:2%;
}
.scaleSlider {
	@media (min-width:0px){
		width:70%;
	}
	@media (min-width:$breakpoint01){
		bottom:4vmin;
	}
}
.scaleSlider  .scaleSliderTrack {
	@media (min-width:0px){
		height: 2vmin;
	}
	@media (min-width:$breakpoint01){
	}
}
.scaleSlider  .scaleSliderThumb {
	@media (min-width:0px){
		top: -1.7vmin;
		width: 4vmin;
		height: 4vmin;
		outline: none;
	}
	@media (min-width:$breakpoint01){
		top: -0.5vmin;
		width: 2vmin;
		height: 2vmin;
	}
}
.scaleSliderThumb {
	@media (min-width:0px){
		cursor: pointer;
		position:absolute;
		z-index: 2;
		background: rgb(230, 230, 255);
		border: 3px solid rgb(40, 20, 140);
		border-radius: 100%;
		box-shadow: 0 0 2px 0 rgb(0 0 0 / 50%);
	}
}
.scaleSliderThumb:hover{
	transition-duration:250ms;
	background: rgb(255, 255, 255);
}
.scaleSliderTrack {
	@media (min-width:0px){
		border-radius: 10px;
		position: relative;
		background: rgb(180, 180, 180);
	}
}
.scaleSliderTrack.scaleSliderTrack-0 {
	background: rgb(130, 100, 250);
}

/* Image size description*/
.imgSize{
	@media (min-width:0px){
		display:flex;
		justify-content: center;
		align-content:  center;
		padding: 0%;
	}
}

/* Save Button */
.saveButtonBox {
	@media (min-width:0px){
		display:flex;
		justify-content: center;
		align-content:  center;
		padding-top:5vmin;
	}
	@media (min-width:$breakpoint01){
		padding:1vmin;
	}
}
.saveButton {
	@media (min-width:0px){
		width:80%;
		background: rgb(230, 230, 255);
		color: rgb(40, 20, 140);
		border: 3px solid rgb(40, 20, 140);
		border-radius: 10px;
		padding:3vmin;
		margin:5%;
		font-size: 6vmin;
	}
	@media (min-width:$breakpoint01){
		width:50%;
		font-size: 4vmin;
		cursor: pointer;
	}
}
.saveButton:hover {
	@media (min-width:0px){
		transition-duration:500ms;
		background: rgb(40, 20, 140);
		border: 3px solid rgb(40, 20, 140);
		color: rgb(255, 255, 255);
	}
}
