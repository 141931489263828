$breakpoint01:	450px;
.paletteOverlay {
    @media (min-width:0px){
		position:fixed;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background-color:rgba(0,0,0,0);
		font-size: larger;
		align-items: center;
		justify-content: center;
		display:flex;
        z-index:3;
	}
	@media (min-width:$breakpoint01){
		
	}
}

.toggleForColorpicker {
	width:30px;
	height:30px;
	border: solid 1px;
	border-color:#aaa;
	border-radius: 5px;
	cursor: pointer;
}
.colorPicker {
    position:relative;
    left:0vw;
    margin:0px;
}
  